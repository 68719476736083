<template>
  <v-container>
    <base-material-card
      icon="mdi-account-network"
      title="Vendedor"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Razón social"
                v-model="vendedor.razon_social"
                :error-messages="errors.razon_social"
                class="required"
              ></v-text-field>
              <v-text-field
                label="RFC"
                v-model="vendedor.rfc"
                :error-messages="errors.rfc"
                class="required"
              ></v-text-field>
              <v-select
                v-model="vendedor.categoria_id"
                :items="categorias"
                label="Categoría"
                item-value="id"
                item-text="nombre"
                :error-messages="errors.categoria_id"
                @change="loadSubcategorias"
                class="required"
              ></v-select>
              <v-select
                v-model="vendedor.subcategoria_id"
                :items="subcategorias"
                label="Subcategoría"
                item-value="id"
                item-text="nombre"
                :error-messages="errors.subcategoria_id"
                class="required"
              ></v-select>
              <v-text-field
                label="Teléfono"
                type="number"
                v-model="telefono"
                :error-messages="errors.telefono"
                @keyup.enter="addTelefono"
                append-outer-icon="fa fa-plus"
                @click:append-outer="addTelefono"
                class="required"
              ></v-text-field>
              <v-list subheader>
                <v-list-item v-for="(item, i) in vendedor.telefono" :key="item">
                  <v-list-item-icon>
                    <v-icon>far fa-address-book</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click="selectDefaultTelefono(i)">
                      <v-icon v-if="i == 0">fa fa-star</v-icon>
                      <v-icon v-if="i > 0">far fa-star</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon @click="removeTelefono(i)">
                      <v-icon>far fa-trash-alt</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-text-field
                label="Email"
                v-model="vendedor.email"
                :error-messages="errors.email"
                class="required"
              ></v-text-field>
              <v-text-field
                label="Límite de crédito"
                v-model="vendedor.credito_limite"
                :error-messages="errors.credito_limite"
                type="number"
                class="required"
              ></v-text-field>
              <v-text-field
                label="Días de crédito"
                v-model="vendedor.credito_dias"
                :error-messages="errors.credito_dias"
                type="number"
                class="required"
              ></v-text-field>
              <v-text-field
                label="Porcentaje de comisión"
                v-model="vendedor.porcentaje_comision"
                :error-messages="errors.porcentaje_comision"
                type="number"
                class="required"
              ></v-text-field>
              <v-text-field
                label="Password"
                v-model="vendedor.password"
                :error-messages="errors.password"
                type="password"
                class="required"
              ></v-text-field>
              <v-text-field
                label="Confirmar password"
                v-model="vendedor.password_confirmation"
                type="password"
                class="required"
              ></v-text-field>

              <v-radio-group
                v-model="vendedor.usuario.enabled"
                :mandatory="true"
              >
                <v-radio label="Habilitado" :value="1"></v-radio>
                <v-radio label="Deshabilitado" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Calle"
                v-model="vendedor.calle"
                :error-messages="errors.calle"
              >
              </v-text-field>
              <v-text-field
                label="Núm. Exterior"
                v-model="vendedor.num_exterior"
                :error-messages="errors.num_exterior"
              ></v-text-field>
              <v-text-field
                label="Núm. Interior"
                v-model="vendedor.num_interior"
                :error-messages="errors.num_interior"
              ></v-text-field>
              <v-text-field
                label="Colonia"
                v-model="vendedor.colonia"
                :error-messages="errors.colonia"
              >
              </v-text-field>
              <v-text-field
                label="Localidad"
                v-model="vendedor.localidad"
                :error-messages="errors.localidad"
              ></v-text-field>
              <v-text-field
                label="Municipio"
                v-model="vendedor.municipio"
                :error-messages="errors.municipio"
              ></v-text-field>
              <v-select
                v-model="vendedor.estado_id"
                :items="estados"
                label="Estado"
                item-value="id"
                item-text="nombre"
                :error-messages="errors.estado_id"
              ></v-select>
              <v-select
                v-model="vendedor.distribuidor_id"
                :items="distribuidores"
                label="Distribuidor"
                item-value="id"
                item-text="name"
                :error-messages="errors.distribuidor_id"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-file-input
                label="Adjuntos"
                v-model="adjunto"
                :error-messages="errors.adjuntos_files"
                @keyup.enter="addAdjunto"
                append-outer-icon="fa fa-plus"
                @click:append-outer="addAdjunto"
              ></v-file-input>
              <v-list subheader>
                <v-list-item
                  v-for="item in vendedor.adjuntos_files"
                  :key="item.name"
                >
                  <v-list-item-icon>
                    <v-icon>far fa-envelope</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                                        <v-btn icon @click="">
                                            <v-icon color="grey lighten-1">mdi-information</v-icon>
                                        </v-btn>
                                    </v-list-item-action> -->
                </v-list-item>
                <v-list-item
                  v-for="item in vendedor.adjuntos"
                  :key="item.name"
                  @click="showFile(item)"
                >
                  <v-list-item-icon>
                    <v-icon>far fa-envelope</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                                        <v-btn icon @click="">
                                            <v-icon color="grey lighten-1">mdi-information</v-icon>
                                        </v-btn>
                                    </v-list-item-action> -->
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="save" :loading="loading"
            >Guardar</v-btn
          >
          <v-btn @click="$router.back()" :loading="loading">Cancelar</v-btn>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    distribuidores: [],
    estados: [],
    categorias: [],
    subcategorias: [],
    email_headers: [
      { text: "Email", value: "", sortable: false },
      { text: "Acciones", value: "accciones", sortable: false },
    ],
    telefono_headers: [
      { text: "Telefono", value: "", sortable: false },
      { text: "Acciones", value: "accciones", sortable: false },
    ],
    email: null,
    telefono: null,
    vendedor: {
      email: [],
      telefono: [],
      adjuntos_files: [],
      distribuidor_id: null,
      usuario: {
        enabled: true,
      },
    },
    adjunto: null,
    errors: {},
    loading: false,
  }),
  mounted() {
    this.loadCategorias();
    this.loadEstados();
    this.loadDistribuidores();
    if (this.$route.params.id) {
      this.load();
    }
  },
  methods: {
    loadDistribuidores() {
      this.$http.get("/distribuidores").then((response) => {
        this.distribuidores = response.data.data;
      });
    },
    loadEstados() {
      this.$http.get("/estados/all").then((response) => {
        this.estados = response.data;
      });
    },
    loadCategorias() {
      this.$http.get("/vendedores/categorias/all").then((response) => {
        this.categorias = response.data;
      });
    },
    loadSubcategorias() {
      this.$http
        .get(
          `/vendedores/categorias/${this.vendedor.categoria_id}/subcategorias`
        )
        .then((response) => {
          this.subcategorias = response.data;
        });
    },
    load() {
      this.$http
        .get(`/vendedores/${this.$route.params.id}`)
        .then((response) => {
          this.vendedor = response.data;
          this.vendedor.adjuntos_files = [];
          this.loadSubcategorias();
        });
    },
    save() {
      this.errors = {};
      this.loading = true;
      if (this.vendedor.id) {
        this.update();
        return;
      }
      this.create();
    },
    create() {
      this.$http
        .post("/vendedores", this.toFormData(this.vendedor))
        .then((response) => {
          this.$router.back();
        })
        .catch((error) => {
          console.log(error);
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;

            default:
              break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$http
        .post(
          `/vendedores/${this.vendedor.id}`,
          this.toFormData(this.vendedor, "PUT")
        )
        .then((response) => {
          this.$router.back();
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;

            default:
              break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addEmail() {
      if (this.email) {
        this.vendedor.email.push(this.email);
        this.email = null;
      }
    },
    addTelefono() {
      if (this.telefono) {
        this.vendedor.telefono.push(this.telefono);
        this.telefono = null;
      }
    },
    removeTelefono(index) {
      console.log(index);
      this.vendedor.telefono.splice(index, 1);
    },
    selectDefaultTelefono(i) {
      let primary = this.vendedor.telefono[i];

      this.vendedor.telefono.splice(i, 1);
      this.vendedor.telefono.unshift(primary);
    },
    addAdjunto() {
      if (this.adjunto) {
        this.vendedor.adjuntos_files.push(this.adjunto);
        this.adjunto = null;
      }
    },
    showFile(item) {
      if (item.url) {
        window.open(item.url);
      }
    },
    toFormData(object, method) {
      let formData = new FormData();

      if (method) {
        formData.append("_method", method);
      }

      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const element = object[key];
        }
        // if (object[key]) {
        if (true) {
          if (Array.isArray(object[key])) {
            object[key].forEach((subobject) => {
              formData.append(`${key}[]`, subobject);
            });
            continue;
          }

          if (typeof object[key] == "object") {
            for (const subkey in object[key]) {
              formData.append(`${key}[${subkey}]`, object[key][subkey]);
            }
            continue;
          }

          formData.append(key, object[key]);
        }
      }
      return formData;
    },
  },
};
</script>